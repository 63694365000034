import * as Sentry from "@sentry/browser"

Sentry.init({
	dsn: "https://848f9235a597641a19b13f258ee0d831@o4508026140622848.ingest.us.sentry.io/4508048373317632",

	environment: import.meta.env.PROD ? "production" : "local",

	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

	// Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 0.1,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ["localhost", "https://renewal.app"],

	// Capture Replay for 1% of all sessions...
	replaysSessionSampleRate: 0.01,

	// ...plus for 5% of sessions with an error
	replaysOnErrorSampleRate: 0.05,

	beforeSend(event) {
		if (!event || !event.exception || !Array.isArray(event.exception.values)) {
			return event // Ensure event structure is valid
		}

		const error = event.exception.values[0]

		// Ignore UnhandledRejection errors with empty value
		if (
			error.type === "UnhandledRejection" &&
			error.value === "Non-Error promise rejection captured with value: "
		) {
			return null
		}

		// Ignore errors related to `javaEnabled` (matomo.js issue)
		if (event.exception.values.some((e) => e.value && e.value.includes("javaEnabled"))) {
			return null
		}

		return event // Send other errors to Sentry
	},
})
